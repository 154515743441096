// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-products-b-maxipro-tsx": () => import("./../../../src/pages/products/b-maxipro.tsx" /* webpackChunkName: "component---src-pages-products-b-maxipro-tsx" */),
  "component---src-pages-products-b-press-tsx": () => import("./../../../src/pages/products/b-press.tsx" /* webpackChunkName: "component---src-pages-products-b-press-tsx" */),
  "component---src-pages-products-b-press-xl-tsx": () => import("./../../../src/pages/products/b-press-xl.tsx" /* webpackChunkName: "component---src-pages-products-b-press-xl-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-resources-downloads-tsx": () => import("./../../../src/pages/resources/downloads.tsx" /* webpackChunkName: "component---src-pages-resources-downloads-tsx" */),
  "component---src-pages-resources-faqs-tsx": () => import("./../../../src/pages/resources/faqs.tsx" /* webpackChunkName: "component---src-pages-resources-faqs-tsx" */),
  "component---src-pages-resources-training-tsx": () => import("./../../../src/pages/resources/training.tsx" /* webpackChunkName: "component---src-pages-resources-training-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-tools-and-accessories-tsx": () => import("./../../../src/pages/tools-and-accessories.tsx" /* webpackChunkName: "component---src-pages-tools-and-accessories-tsx" */),
  "component---src-pages-where-to-buy-tsx": () => import("./../../../src/pages/where-to-buy.tsx" /* webpackChunkName: "component---src-pages-where-to-buy-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/case-study.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

